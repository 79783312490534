@import '@aws-amplify/ui-react/styles.css';

@import url('https://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext');

.amplify-button[data-variation='primary'] {
  background: #e54b0e;
}
.amplify-tabs {
  display: none;
}
.data-amplify-router {
  border-radius: 30px;
}
.amplify-link {
  color: #505050;
}
.amplify-button[data-variation='link'] {
  color: #505050;
}
.amplify-button[data-variation='link']:hover {
  background-color: white;
}
[data-amplify-authenticator] [data-amplify-router] {
  box-shadow: none;
  border-color: rgba(80, 80, 80, 0.2);
  border-radius: 10px;
}
*:focus {
  outline: none;
}
.amplify-input:focus {
  border-color: rgba(229, 75, 14, 0.9);
  box-shadow: 1px 1px 1px 1px rgba(229, 75, 14, 0.1);
}
.amplify-heading {
  align-items: center;
}
.amplify-image {
  width: 70%;
}
