@import './constants.scss';
@import './Login.scss';
@import './Navbar.scss';
@import './Onboarding.scss';
@import './Orders.scss';
@import './components/';
@import './ProductLocations.scss';
@import './Home.scss';
@import './Webhooks.scss';

@import url('https://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext');

body {
  margin: 0;
  font-family: 'Roboto', 'BlinkMacSystemFont', 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: unset;
}

.ButtonBack {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Main {
  padding: 1em;
  flex-grow: 100;
  display: flex;
  flex-direction: column;
}

.Layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.Strecth {
  align-items: stretch;
}

.Row {
  align-items: center;
  display: flex;
  justify-content: center;
}

.Column {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Start {
  justify-content: flex-start;
}

.Wrap {
  flex-wrap: wrap;
}

.Center {
  justify-content: center;
}

.Rounded {
  border-radius: 10px;
}

.Between {
  justify-content: space-between;
}

.Around {
  justify-content: space-evenly;
}

.Bold {
  font-weight: bold;
}

.Normal {
  font-weight: normal;
}

.Grow {
  flex-grow: 1;
}

.Border {
  padding: 5px;
  border: 1px solid #aaa;
  margin-bottom: 10px;
}

p {
  margin: 0.5em;
}

h2 {
  margin: 0.2em 0 0.2em 0;
}

h3 {
  margin: 0.5em 0 0.2em 0;
}

.Flex1 {
  flex: 1;
}

.Flex2 {
  flex: 2;
}

.Flex3 {
  flex: 3;
}

.Flex6 {
  flex: 6;
}

.Paper {
  padding: 0.3em;
  margin-bottom: 10px;
}

.MarginTop {
  margin-top: 1em;
}

.BackgroundPrimary {
  background-color: $color-primary;
  color: $color-secondary;

  h2 {
    font-weight: 500;
  }
}

.BackgroundInfo {
  background-color: $color-info;
  color: black;

  h2 {
    font-weight: 500;
  }
}
