@import 'constants.scss';

.OrderContainer {
  display: flex;
  flex-direction: row;
}

.OrdersToolbar {
  display: flex;
  justify-content: end;
  padding: 0.1em 0;
}

.OrdersTableToolbar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex: 1;
  align-items: center;

  .InputField {
    margin: 0 1em;
  }
}

#OrderSearch {
  max-width: 300px;
}

.Left {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;
}

.Right {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: end;
  align-items: center;
}

.OrderFilterContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.OrderFilterItem{
  &:nth-child(n + 2) {
    padding-left: 1vw; 
  }
}

#OrderData {
  width: 20em;
}

#OrderDetails {
  flex-grow: 1;
  margin-left: 1em;
}

#OrderDetailsHeader {
  margin: 6px;
}

.Search {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-left: 1em;
}

.SearchContainer {
  flex-grow: 1;
  display: flex;
}

.ReturnIcon {
  width: 0;
  height: 0;
  z-index: 1;
  position: relative;
  top: 1.3em;
  right: 2em;
  color: $color-dark-gray;
  border-radius: 50%;
  opacity: 0.5;
}

.Active {
  opacity: 1;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: $color-primary;
  }
}

.SearchInput {
  flex-grow: 1;
}

.Sku {
  font-size: small;
}

.OrderDetail p.ProductName {
  margin: 0.1em 0em;
}

.InputLabel {
  margin: 0.5em;
}

.InputField {
  width: 12em;
}

.EditButtons {
  margin-top: 1em;
}

.EditButtons button {
  margin: 0 0.5em;
}

.NumberInput {
  width: 90%;
  max-width: 7em;
  border: none;
  border-bottom: 1px solid #aaa;
}

.SummaryLabel {
  margin: 0.5em 0 0.5em 0;
  width: 15em;
}

.Placeholder {
  font-style: italic;
  color: rgba(0, 0, 0, 0.6);
}

#NotFoundProducts {
  background-color: #ddd;
}

.Loading {
  margin: 0 1em;
}

.FormBorder {
  margin: 20px 0;
  padding-top: 5px;
  padding-bottom: 15px;
  border: 1px solid #aaa;
  margin-bottom: 10px;
}

.FormInputAllWidth {
  width: 95%;
}

.ModalBox {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: white;
  padding: 1rem;
  justify-content: center;
}

.DetailHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: small;
}

.PackLabel {
  color: #e54b0e;
  font-weight: bold;
  margin-left: 0.3em;
}

.OrderDetailNote {
  position: absolute;
  width: 55%;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid #000;
  padding: 20px;
  border-radius: 10px;
}

.ItemsWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  justify-content: flex-start;
  gap: 2vw;

  h3 {
    margin: 0;
  }
}
