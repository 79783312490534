.AccessDeniedPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100vh;
  font-family: 'Work Sans', 'Roboto', sans-serif;
  width: 100%;
}

.AccessDeniedPage .Button {
  background-color: rgba(229, 75, 14, 0.8);
  color: white;
  padding: 0.7vw;
  border-radius: 8px;
}

.AccessDeniedPage .Button:hover {
  cursor: pointer;
}
