@import './constants.scss';

.Home {
  align-self: center;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .HomeTitle {
    text-align: center;
    margin-bottom: 0.5em;
    font-size: 1.5em;
    font-weight: bold;
    color: $color-dark-gray;
  }

  .LocalsFilter {
    margin-top: 0;
    margin-bottom: 1em;
  }

  .LocalCard {
    margin-bottom: 1em;
    padding: 1em;
    border-radius: 0.3em;
    box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
    transition: 0.2s ease-in-out;

    &:focus {
      box-shadow: rgba(99, 99, 99, 0.7) 0px 2px 8px 0px;
      background-color: $color-lighter-gray;
    }

    &:last-child {
      margin-bottom: 0;
    }

    img {
      object-fit: cover;
      width: 3em;
      height: 3em;
      border-radius: 50%;
    }

    .LocalCardChildren {
      margin-right: 1em;
      font-weight: bold;
      color: $color-dark-gray;
    }
  }
}
