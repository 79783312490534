#ProductLocationSearch {
  max-width: 300px;
}

.Loss {
  position: absolute;
  width: 55%;
  height: 50%;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid #000;
  padding: 20px;
  border-radius: 10px;
}

.ModalForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.Quantity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
