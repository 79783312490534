@import './constants.scss';

.Webhooks {
  display: flex;
  flex-direction: column;
  align-items: center;

  .Title {
    font-size: 1.5vw;
    font-weight: bold;
    text-align: center;
    margin: 2vh 0;
  }

  .SubTitle {
    font-size: 1vw;
    font-weight: bold;
  }

  .WebhooksForm {
    background-color: $color-third;
    width: 50%;
    padding: 15px;
    border-radius: 10px;
    box-shadow: $shadow-light;

    .InputWrapper {
      margin-bottom: 1vh;

      .InputTitle {
        font-size: 1vw;
      }

      input[type='checkbox'] {
        width: 1.2em;
        height: 1.2em;
      }
    }

    .InputWrapperRow {
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 45%;
    }

    .Button {
      font-size: 1vw;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: $color-primary;
      color: $color-secondary;
      border-radius: 7px;
      min-height: 3vh;
      max-width: 30%;
      box-shadow: $shadow-light;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &.disabled {
        background-color: $color-info;
        pointer-events: none;
      }

      &:hover {
        cursor: pointer;
      }

      &:hover:not(.disabled) {
        background-color: $color-primary-transition;
        box-shadow: $shadow-dark;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }
  }
}
