$color-primary: rgb(229, 75, 14);
$color-secondary: #fff;
$color-third: #e0e0e0;
$color-info: #c0c0c0;
$color-primary-transition: rgb(160, 52, 9);
$color-dark-gray-transition: rgb(35, 35, 35);
$shadow-light: rgba(0, 0, 0, 0.04) 0px 3px 5px;
$shadow-dark: rgba(0, 0, 0, 0.2) 0px 3px 5px;
$alert-background: rgba(29, 29, 27, 0.5);
$color-gray: #bbb;
$color-light-gray: #c6c6c6;
$color-lighter-gray: #f0f0f0;
$color-dark-gray: #333;
