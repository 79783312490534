.checkbox input[type='checkbox'] {
  display: none;
}

.checkbox input[type='checkbox']:checked + .wrapper {
  background-color: $color-primary;
}

.SwitchButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.checkbox input[type='checkbox']:checked + .wrapper .knob {
  left: 20px;
}

.checkbox .wrapper {
  background-color: $color-info;
  border-radius: 13px;
  width: 47px;
  height: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
}

.checkbox .knob {
  background-color: white;
  border-radius: 100%;
  display: inline-block;
  margin-left: 5px;
  position: relative;
  width: 16px;
  height: 16px;
  left: 0;
  transition: left 100ms ease-in-out 0s;
}
