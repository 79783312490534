@import './constants.scss';

.Onboarding {
  display: flex;
  flex-direction: column;
  align-items: center;

  .Title {
    font-size: 1.5vw;
    font-weight: bold;
    text-align: center;
    margin: 2vh 0;
  }

  .OnboardingForm {
    background-color: $color-third;
    width: 50%;
    padding: 15px;
    border-radius: 10px;
    box-shadow: $shadow-light;

    .AddedLocals {
      background-color: $color-lighter-gray;
      padding: 1vw;
      margin-bottom: 1vh;
      border-radius: 7px;
    }

    .MinimizeTitle {
      font-size: 1vw;
    }

    .Divider {
      border-top: 2px solid $color-info;
    }

    .InputWrapper {
      margin-bottom: 1vh;

      .InputTitle {
        font-size: 1vw;
      }

      .Bold {
        font-weight: 600;
      }

      .InputDescription {
        font-size: 0.8vw;
      }

      input[type='checkbox'] {
        width: 1.2em;
        height: 1.2em;
      }
    }

    .Button {
      font-size: 1vw;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: $color-primary;
      color: $color-secondary;
      border-radius: 7px;
      min-height: 3vh;
      max-width: 30%;
      box-shadow: $shadow-light;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &.disabled {
        background-color: $color-info;
        pointer-events: none;
      }

      &:hover {
        cursor: pointer;
      }

      &:hover:not(.disabled) {
        background-color: $color-primary-transition;
        box-shadow: $shadow-dark;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }

    .InlineDisplay {
      display: flex;
      align-items: end;
      font-size: 85%;
      margin-bottom: 0.5vw;
      flex: 1;

      &.AllRow {
        justify-content: space-between;
      }

      span {
        font-weight: 600;
      }

      span:not(:nth-child(1)) {
        margin-left: 1vw;
      }

      div:not(:nth-child(1)) {
        margin-left: 1vw;
      }
    }

    .Margin {
      margin-bottom: 1vh;
      padding: 4px;
    }

    .Secondary {
      padding-inline: 4px;
      background-color: $color-dark-gray;

      &:hover:not(.disabled) {
        background-color: $color-dark-gray-transition;
        box-shadow: $shadow-dark;
      }
    }
  }

  .Chip {
    display: flex;
    align-items: center;
    background-color: $color-gray;
    border-radius: 0.9em;
    padding: 0.1em 0.3em;
    margin: 0.1em;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background-color: $color-light-gray;
    }
  }
}
